import React from 'react';

import Layout from '../components/layout';

class InnePage extends React.Component {
    render() {
        return (
            <Layout location={this.props.location.pathname}>
                <div style={{
                    fontSize: `1.2em`,
                    lineHeight: `1.8em`,
                    margin: `0 3em`,
                }}>
                    <h2 style={{
                        marginTop: `4em`,
                        textAlign: `center`,
                        textDecoration: `underline`,
                    }}>OFERTA "NAMIOTU PROFILAKTYCZNEGO" - PROMUJ PROFILAKTYKĘ <nobr>W SWOJEJ</nobr> GMINIE</h2>
                    <h4 style={{ textAlign: `center` }}>FIRMA SBC <nobr>W ZWIĄZKU</nobr> <nobr>Z PAŃSTWA</nobr> POTRZEBAMI WPROWADZIŁA DO SWOJEJ OFERTY NAMIOT PROFILAKTYCZNY.</h4>
                    <p>W OFERCIE NAMIOTU ZNAJDĄ PAŃSTWO:</p>
                    <ul>
                        <li>4 GODZINY DYDAKTYCZNE</li>
                        <li>KONSULTACJE <nobr>Z CERTYFIKOWANYM</nobr> TRENEREM</li>
                        <li>MATERIAŁY PROFILAKTYCZNE</li>
                        <li>PREZENTACJA WALIZKI <nobr>Z ATRAPAMI</nobr> NARKOTYKÓW <nobr>I DOPALACZY</nobr></li>
                        <li>ALKOGOGLE</li>
                    </ul>
                    <p>ORAZ DODATKOWE  OPCJE <nobr>W ZALEŻNOŚCI</nobr> OD INDYWIDUALNYCH POTRZEB KLIENTA.</p>

                    <h5 style={{
                        textAlign: `center`,
                        marginTop: `3em`,
                        fontSize: `1.3em`,
                    }}>
                        <b>
                            WIĘCEJ INFORMACJI UZYSKAJĄ PAŃSTWO POD NUMEREM TELEFONU <a href={`tel:12-422-54-70`}>12-422-54-70</a>
                        </b>
                    </h5>

                </div>
            </Layout>
        )
    }
}

export default InnePage